import classnames from 'classnames';
import Clipboard from 'clipboard';
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as UrlIcon } from './url.svg';

import styles from './styles.module.css';

const ShareBar = ({ text, url = 'https://q.devamo.com' }) => {
  const [showCopied, setShowCopied] = useState(false);
  const urlButton = useRef();

  useEffect(() => {
    const clipboard = new Clipboard(urlButton.current);

    clipboard.on('success', () => {
      setShowCopied(true);

      setTimeout(() => {
        setShowCopied(false);
      }, 1000);
    });

    return () => {
      clipboard.destroy();
    };
  }, [url]);

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.button, styles.url)}
        data-clipboard-text={url}
        ref={urlButton}
      >
        {showCopied && <div className={styles.copied}>Url copied!</div>}
        <UrlIcon />
      </div>
    </div>
  );
};

ShareBar.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

export default ShareBar;
